<template>
  <div></div>
</template>

<script>
export default {
  name: "addd",
  props: [""],
  data: () => {
    return {};
  },
  methods: {},
  mounted() {}
};
</script>

<style >
.text-small {
}
</style>